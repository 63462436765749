@import "../../../variables.scss";

.GenericInputs {
    input {
        box-sizing: border-box;
        border: none;
        font-size: 1.3rem;
        box-shadow: $shadow-input-1;
        transition: box-shadow 0.2s ease-in;
        border-radius: 0;
        padding: 0.75rem;
        transition: $transition;
      }
    
      input:focus {
        box-shadow: $shadow-input-focus-1;
        outline: none;
      }
    
      input::-webkit-input-placeholder {
        opacity: 0.5;
        transition: opacity 0.25s ease-out;
      }
    
      input:hover::-webkit-input-placeholder,
      input:focus::-webkit-input-placeholder {
        opacity: 0;
      }
    
      label {
        align-self: start;
      }
}