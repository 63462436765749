@import "../../../variables.scss";

.Counter {
  position: absolute;
  top: -2px;
  left: -2px;
  background-color: $color-gold;
  color: black;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;
}
