@import "../../../variables.scss";

@mixin hover-active {
  &:hover,
  &:active {
    @content;
  }
}

.Button {
  padding: 0px;

  .buttonGradient {
    height: 3.7rem;
    background-size: 200% 100%;
    transition: $transition;
    text-transform: uppercase;
    display: inline-block;
    // font-size: 1.5rem;
    opacity: 1;
    padding: 1rem 1rem;
    font-weight: 700;
    line-height: 1.1;
    white-space: nowrap;
    border-radius: 3px;
    color: rgba($color-white, 1);
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.125rem;
    width: 100%;
    border: none;
    background-position: -50% 0;
    background-color: $color-white;

    &:disabled {
      opacity: 0.5; // Making the button semi-transparent when disabled
      cursor: not-allowed; // Changing the cursor to indicate the button is not clickable
      background-image: none; // Removing the gradient background
      background-color: $color-light-grey; // Adding a grey background to indicate it's disabled
      color: $color-dark;

      @include hover-active() {
        background-position: -250% 0;
        box-shadow: none !important;
      }
    }
  }

  .primary {
    background-image: linear-gradient(
      to right,
      rgba($color-gold, 1),
      rgba($color-gold, 0.4),
      rgba($color-gold, 1)
    );

    @include hover-active() {
      background-position: -250% 0;
      box-shadow: 0 0.5rem 1rem rgba($color-gold, 0.5);
    }
  }

  .secondary {
    background-image: linear-gradient(
      to right,
      rgba($color-dark, 1),
      rgba($color-dark, 0.6),
      rgba($color-dark, 1)
    );
    @include hover-active() {
      background-position: -250% 0;
      box-shadow: 0 0.5rem 1rem rgba($color-dark, 0.5);
    }
  }

  .success {
    background-image: linear-gradient(
      to right,
      rgba($color-green, 1),
      rgba($color-green, 0.6),
      rgba($color-green, 1)
    );
    @include hover-active() {
      background-position: -250% 0;
      box-shadow: 0 0.5rem 1rem rgba($color-green, 0.5);
    }
  }

  .danger {
    background-image: linear-gradient(
      to right,
      rgba($color-red, 1),
      rgba($color-red, 0.6),
      rgba($color-red, 1)
    );
    @include hover-active() {
      background-position: -250% 0;
      box-shadow: 0 0.5rem 1rem rgba($color-red, 0.5);
    }
  }
}

// Font Weights
// $thin: 100;
// $light: 300;
// $regular: 400;
// $semibold: 600;
// $bold: 700;
// $ultra: 800;

// Colors
$black: $color-dark;
$white: $color-white;

// Material Colors
// $dark-theme: (
//   primary: rgba($black, 0.87),
//   secondary: rgba($black, 0.54),
//   disabled: rgba($black, 0.26),
// );
// $light-theme: (
//   primary: rgba($white, 1),
//   secondary: rgba($white, 0.7),
//   disabled: rgba($white, 0.3),
// );

// Pen Colors
// $accent: $color-green;

// Base Setting
// $base-color: map-get($dark-theme, primary);
// $base-font-size: 18px;
// $base-font-weight: $regular;
// $base-line-height: 1.5em;

// Layout Settings
// $max-width: 100px;
// $min-width: 110px;
// $columns: null;
// $gutters: null;

// Elevation
@mixin elevation($level) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($white, 0.1), 0 0 0 5px rgba($white, 0.1),
      0 0 0 10px rgba($white, 0.1), 0 0 0 15px rgba($white, 0.1);
  }
  100% {
    box-shadow: 0 0 0 5px rgba($white, 0.1), 0 0 0 10px rgba($white, 0.1),
      0 0 0 15px rgba($white, 0.1), 0 0 0 20px rgba($white, 0);
  }
}

.btnDropDown {
  position: relative;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  color: $white;
  font-weight: 600;
  font-size: 1rem !important;
  text-transform: uppercase;
  // overflow: hidden;
  cursor: pointer;
  border: none;
  transition: $transition;
  background-color: $color-green !important;
  
  &:hover {
    background-color: rgba($color: $color-green, $alpha: 0.8) !important;
    box-shadow: $shadow-3;
    color: rgba($color: $color-dark, $alpha: 0.8) ;
  }

  &:disabled {
    color: white !important;
  }

  

  // &:focus,
  // &:active {
  //   .dropdown {
  //     transform: translate(0, 20px) !important;
  //     opacity: 1 !important;
  //     visibility: visible !important;
  //   }
  // }

  .material-icons {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ripple 0.6s linear infinite !important;
  }

  // .dropdown {
  //   position: absolute;
  //   z-index: 100;
  //   top: 75%;
  //   right: 0;
  //   background: $white;
  //   width: 150%;
  //   border-radius: 4px;
  //   box-shadow: 0 4px 12px rgba($black, 0.1);
  //   text-align: left;
  //   opacity: 0;
  //   visibility: hidden;
  //   transition: 0.3s ease;
  //   border: 2px solid $color-gold;

  //   &:before {
  //     content: "";
  //     position: absolute;
  //     top: -6px;
  //     right: 15px;
  //     width: 0;
  //     height: 0;
  //     box-shadow: 2px -2px 6px rgba($color-dark, 0.05);
  //     border-top: 6px solid $color-gold;
  //     border-right: 6px solid $color-gold;
  //     border-bottom: 6px solid transparent;
  //     border-left: 6px solid transparent;
  //     transform: rotate(-45deg);
  //     mix-blend-mode: multiple;
  //   }
  // }
}
