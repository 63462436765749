@import "./variables.scss";

body {
  // margin: 0;
  font-family: "Manrope", sans-serif;
  // font-family: "Poppins", sans-serif; // Use 'sans-serif' as a fallback
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-dark !important;
  // max-width: 100vh;
  // max-height: 100vh;
  overflow: hidden;
  // line-height: 1.4 !important;
  // font-weight: 600;
  font-size: 1.05rem !important;
  font-weight: 500 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Extra small devices (phones) */
@media (max-width: $lw-screen-xs-max) {
  html {
    font-size: $lw-font-size-xs;
  }
}
/* Small devices (tablets) */
@media (min-width: $lw-screen-sm-min) and (max-width: $lw-screen-sm-max) {
  html {
    font-size: $lw-font-size-sm;
  }
}
/* Medium devices (desktops) */
@media (min-width: $lw-screen-md-min) and (max-width: $lw-screen-md-max) {
  html {
    font-size: $lw-font-size-md;
  }
}
/* Large devices (desktops) */
@media (min-width: $lw-screen-lg-min) and (max-width: $lw-screen-lg-max) {
  html {
    font-size: $lw-font-size-lg;
  }
}
/* Extra large devices (large desktops) */
@media (min-width: $lw-screen-xl-min) {
  html {
    font-size: $lw-font-size-xl;
  }
}

.btn {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

.border-1 {
  border-radius: 0.25rem;
}
.border-2 {
  border-radius: 30px;
}
.border-3 {
  border-radius: 45px !important;
}

.shadow-1 {
  box-shadow: $shadow-1;
}

.shadow-2 {
  box-shadow: $shadow-2;
}

.shadow-input-1 {
  box-shadow: $shadow-input-1;
}

.shadow-input-focus-1 {
  box-shadow: $shadow-input-focus-1;
}

.color-gold {
  color: $color-gold;
}

.cursor-pointer {
  cursor: pointer;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;

}

.pr-3 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-right: 2rem;

}

.mw-50 {
  max-width: 50%;
}

.pr-0 {
  padding-right: 0rem;
}

.pl-0 {
  padding-left: 0rem !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

@import "../node_modules/bootstrap/scss/bootstrap";

.shadow-sm {
  box-shadow: $shadow-3 !important;
}

.icon-sm {
  width: 1.125rem; // or 24px if you prefer
  height: 1.125rem; // or 24px if you prefer
}

.icon-md {
  width: 1.5em; // or 24px if you prefer
  height: 1.5em; // or 24px if you prefer
}

.icon-lg {
  width: 2rem; // or 24px if you prefer
  height: 2rem; // or 24px if you prefer
}

.icon-xl {
  width: 2.1875rem; // or 24px if you prefer
  height: 2.1875rem; // or 24px if you prefer
}

.icon-xxl {
  width: 8.75rem;
  height: 8.75rem;
}

.customScroll {
  max-height: 95vh; /* or any value that suits your needs */
  overflow-y: auto;
  overflow-x: hidden;
  animation: fadeInOutText 1s linear;
}

/* Customizing the scrollbar: Works on Chrome, Edge, and Safari */
.customScroll::-webkit-scrollbar {
  width: 0.3rem;
  margin-left: 0.6rem; /* avoid width reflow */
}

.customScroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px; /* You can add this to have rounded corners */
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 5px; /* You can add this to have rounded corners */
}

/* Firefox */
.customScroll {
  scrollbar-color: orange white;
}

.flex-auto {
  flex: auto !important;
}

.background-green-light {
  background-color: $color-green-light;
}

.w-fit-content {
  width: fit-content;
}

.tableDisableLayout{
  z-index: 10;
  background-color: rgba($color-darker, 0.6);
}