@import "../../../variables.scss";

.PaymentBox {

  background-color: $color-darker;
  // color: white;

  h3.payment-title {
    text-align: left;
    font-weight: 500;
    color: white !important;
  }

  .row-payment-method {
    margin: 0px 0px 0px 0px;
    padding: 22px 0px 11px 0px;
    text-align: left;
    display: table;
  }
  .payment-row {
    background-color: rgba($color-dark, 0.2);
    color: $color-white;
    width: 100%;
  }
  .payment-row-last {
    color: white !important;
    width: 100%;
  }

  .select-icon {
    display: table-cell;
    vertical-align: top;
    text-align: left;
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    width: 24px;
    height: 24px;
  }

  .select-txt {
    display: table-cell;
    vertical-align: middle;
    word-wrap: break-word;
    height: 60px;
    text-align: left;
    padding-left: 15px;
    font-size: 12pt;
    // color: $color-darker !important;
  }

  .pymt-type-name {
    font-weight: 500;
    font-size: 12pt;
    padding-bottom: 8px;
    // color: $color-darker;
  }
  .pymt-type-desc {
    padding-bottom: 22px;

    width:70%;
      font-size:14px;
  }

  .select-logo {
    padding-right: 0px;
    vertical-align: top;
        right: 35px;
      // position: absolute;

      img {
        width: 5rem;
        height: auto;
      }
  }

  .cc-title {
    font-size: 10.5pt;
    padding-bottom: 8px;
  }
  .cc-field {
    padding-top: 15px;
    padding-right: 30px;
    display: table-cell;
  }

  .form-cc {
    display: table;
    width: 100%;
    text-align: left;
    padding: 0px 0px 30px 30px;
  }
  .row-cc {
    display: table;
    width: 100%;
    padding-bottom: 7px;
  }

  .input {
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 38px;
    padding-left: 15px;
    font-weight: 600;
    font-size: 11pt;
    color: $color-dark;
   
  }
  input[type="text"] {
     display: initial;
    padding:15px
  }


  input[id^="radio"]{
    display:none;
 }
 
 input[id^="radio"] + label
 {
     width: 1.25rem;
     height: 1.25rem;
     border-radius: 50%;
     border: 10px solid #ccc;
 }
 
 input[id^="radio"]:checked + label
 {
    border: 10px solid $color-gold;
 }

}

.stepBody {
  max-height: 68vh;
  overflow-y: auto;
  

  .list-group-item{
    color:white;
    background-color: $color-dark;
    border-bottom: 4px solid $color-darker;
  }
}

.stepper-head{
  .stepper-indicator-info{
    color: $color-dark !important;
  }
  
  &.is-active{
    
      color: $color-gold !important;
    
  }
}

.stepper-step {
  &.is-active {
    .stepper-indicator-info {
      background-color: $color-gold !important;
    }
    .stepper-label{
      color: $color-gold !important;
    }
  }
}