@import "../../../variables.scss";

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 0.25rem solid rgba($color-darker, 0.1);
  // border-top: 0.25rem solid $color-gold; /* Spinner color */
  border-radius: 50%;
  width: 2rem; /* Adjust the size to your preference */
  height: 2rem; /* Adjust the size to your preference */
  animation: spin 1s linear infinite;
}

.white {
  border-top: 0.25rem solid $color-white !important; /* Spinner color */
}

.gold {
  border-top: 0.25rem solid $color-gold !important; /* Spinner color */
}

.hm-spinner{
  height: 6.625rem;
  width: 6.625rem;
  border: 0.25rem solid transparent;
  border-top-color: $color-gold;
  border-bottom-color: $color-gold;
  border-radius: 50%;
  position: relative;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

.hm-spinner::before{
  content: "";
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  bottom: 0.8rem;
  left: 0.8rem;
  border: 0.25rem solid transparent;
  border-top-color: rgba($color-gold, 0.7);
  border-bottom-color: rgba($color-gold, 0.7);
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;

}

.nb-spinner {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  background: transparent;
  border-top: 0.25rem solid $color-gold;
  border-right: 0.25rem solid transparent;
  border-radius: 50%;
  -webkit-animation: 500ms spin linear infinite;
  animation: 300ms spin linear infinite;
}

.triple-spinner {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid rgba($color-gold, 0.4);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}
.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: rgba($color-gold, 0.7);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: rgba($color-gold, 1);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
