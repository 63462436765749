@import "../../variables.scss";

@keyframes fadeIn {
  0% {
    // font-size: $text-mid-size !important;
    opacity: 0;
  }
  100% {
    // font-size: $text-start-size !important;
    opacity: 1;
  }
}

.UserProfile {
  background-color: rgba($color-gold, 0.1);
  overflow: hidden;

  ::-webkit-scrollbar {
    right: 0;
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffc078;
    border-radius: 8px;
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .FadeIn {
    animation: fadeIn 500ms linear;
  }
}

.FrameLayout {
  background-image: linear-gradient(
    to right,
    rgba($color-gold, 0),
    rgba($color-gold, 1),
    rgba($color-gold, 1),
    rgba($color-gold, 1),
    rgba($color-gold, 1)
  );
  position: fixed;
  height: 200%;
  width: 100%;
  transform: rotate(18deg);
  right: -92%;
  top: -58%;
  z-index: -2;
}
