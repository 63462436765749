// Variable overrides first
// Colors
$color-gold: rgb(255, 170, 72);
$color-gold-darker: rgb(255, 184, 103);
$color-dark: rgba(31, 32, 35, 1);
$color-darker: rgb(26, 27, 30);
$color-light-grey: #eee;
$color-green: #47c9a2;
$color-green-light: #47c9a2;
$color-red: rgb(253, 69, 69);
$color-white: #fff;
$primary: $color-gold;
$secondary: $color-dark;
$danger: $color-red;
$dark: $color-dark;
$light-dark: $color-light-grey;
$success: $color-green;

$lw-font-size-xs: 10px;
$lw-font-size-sm: 11px;
$lw-font-size-md: 12px; /* Adjusted for medium devices */
$lw-font-size-lg: 13px; /* Adjusted for large devices */
$lw-font-size-xl: 16px; /* Adjusted for extra-large devices */

$lw-screen-xs-max: 575.98px;

$lw-screen-sm-min: 576px;
$lw-screen-sm-max: 767.98px;

$lw-screen-md-min: 768px;
$lw-screen-md-max: 1199.98px;

$lw-screen-lg-min: 1200px;
$lw-screen-lg-max: 1799.98px;

$lw-screen-xl-min: 1800px;

$border-radius-1: 15px;
$border-radius-2: 30px;
$border-radius-3: 45px;

$shadow-1: -3px 0px 20px rgba(black, 0.9);
$shadow-2: inset 0px 0px 13px 0px rgb($color-gold, 0.2);
$shadow-3: 0 0.125rem 0.7rem rgba(0, 0, 0, 0.075);
$shadow-input-1: inset 0px -3px 0px 0px rgba($color-dark, 0.2);
$shadow-input-focus-1: inset 0px -3px 0px 0px rgba($color-gold, 0.7);

$transition: all 0.3s ease-in-out;

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Medium.ttf") format("truetype");
  font-weight: 500; // Use the numeric value for 'Light'
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600; // Use the numeric value for 'Normal'
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Bold.ttf") format("truetype");
  font-weight: 700; // Use the numeric value for 'Bold'
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 400; // Use the numeric value for 'Light'
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500; // Use the numeric value for 'Light'
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600; // Use the numeric value for 'Normal'
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700; // Use the numeric value for 'Bold'
  font-display: swap;
}

