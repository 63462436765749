@import '../../../variables.scss';

.Input {
    input[type=checkbox] + label {
        display: block;
        cursor: pointer;
      }
      
      input[type=checkbox] {
        display: none;
      }
      
      input[type=checkbox] + label:before {
        content: "\2713";
        border: 0.14em solid $color-gold;
        border-radius: 0.2em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.8rem;
        vertical-align: bottom;
        color: transparent;
        transition: .2s;
      }
      
      input[type=checkbox] + label:active:before {
        transform: scale(0);
      }
      
      input[type=checkbox]:checked + label:before {
        background-image: linear-gradient(to right, rgba($color-gold, 1), rgba($color-gold,1), rgba($color-gold, 1),);
        border-color: $color-gold;
        color: $color-white;
        font-weight: bold;
      }
      
      input[type=checkbox]:disabled + label:before {
        transform: scale(1);
        border-color: rgba($color-gold, 0.4);
      }
      
      input[type=checkbox]:checked:disabled + label:before {
        transform: scale(1);
        background-color: rgba($color-green, 0.3);
        border-color: rgba($color-green, 0.3);
      }
}