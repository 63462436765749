@import "../../../variables.scss";

.circle {
  width: 6rem;
  height: 6rem;
  background-color: $color-gold;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  margin: 0 auto 10px;
}
