@import "../../../variables.scss";

.stepper-wrapper {
    * {
      box-sizing: border-box;
    }
  
    /* styles of default stepper */
    .stepper-head {
      display: flex;
      position: relative;
      width: 100%;
      user-select: none;
  
      /* styles of inline stepper */
      &.inline-stepper-head {
        .stepper-step {
          &:after {
            top: 50%;
            transform: translateY(-50%);
          }
        }
  
        .stepper-indicator,
        .stepper-label {
          display: inline-block;
          vertical-align: middle;
        }
  
        .stepper-label {
          text-align: left;
          padding: 0 0.5rem;
          background: $color-white;
        }
      }
  
      /* styles of vertical stepper */
      &.vertical-stepper-head {
        flex-direction: column;
        width: auto;
  
        .stepper-step {
          display: flex;
          align-items: center;
          text-align: start;
  
          &:after {
            content: " ";
            position: absolute;
            left: 1.5rem;
            right: auto;
            top: 2rem;
            bottom: -0.5rem;
            width: 0;
            height: auto;
            border-left: 0.125rem solid $color-light-grey;
          }
  
          &.rightToLeft {
            &:after {
              right: 1.5rem;
              left: auto;
            }
          }
        }
  
        .stepper-label {
          padding-inline-start: 1rem;
        }
  
        + .stepper-body {
          margin-inline-start: 3.5rem;
        }
      }
  
      .stepper {
        &-step {
          position: relative;
          text-align: center;
          padding: 0.5rem;
          flex-basis: 100%;
  
          &.rightToLeft {
            &:after {
              left: -50%;
            }
          }
  
          &.is-complete {
            .stepper-indicator-info {
              border-color: $color-green;
              background-color: $color-green;
              cursor: pointer;
            }
  
            .stepper-label {
              color: $color-green;
            }
  
            // &:after {
            //   background-color: #4caf50;
            // }
          }
  
          &.is-active {
            .stepper-indicator-info {
              border-color: $color-dark;
              background-color: $color-dark;
              cursor: initial;
            }
  
            .stepper-label {
              color: $color-dark;
            }
          }
  
          &.is-warning {
            .stepper-indicator-info {
              border-color: $color-gold;
              background-color: $color-gold;
            }
  
            .stepper-label {
              color: $color-gold;
            }
  
            // &:after {
            //   background-color: #f1c40f;
            // }
          }
  
          &.is-error {
            .stepper-indicator-info {
              border-color: $color-red;
              background-color: $color-red;
            }
  
            .stepper-label {
              color: $color-red;
            }
  
            // &:after {
            //   background-color: #e95a4b;
            // }
          }
  
          &:after {
            content: " ";
            position: absolute;
            left: 50%;
            top: 1.5rem;
            width: 100%;
            height: 0.125rem;
            background-color: $color-light-grey;
            z-index: 1;
          }
  
          &:last-child:after {
            display: none;
          }
        }
  
        &-indicator {
          position: relative;
          display: block;
          z-index: 2;
  
          &-info {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border: 1px solid $color-light-grey;
            border-radius: 50%;
            background-color: $color-light-grey;
            font-size: 1rem;
            line-height: 2.5rem;
            text-align: center;
            color: $color-white;
            z-index: 2;
  
            .stepper-tick {
              width: 14px;
              fill: $color-white;
            }
          }
        }
  
        &-label {
          position: relative;
          display: block;
          margin: 0.5rem 0;
          color: $color-light-grey;
          z-index: 2;
        }
      }
    }
  
    .stepper-body {
      flex: 1;
      min-height: 100px;
      padding: 10px 0;
    }
  
    .a {
      display: flex;
      align-items: center;
      padding-top: 10px;
  
      &-btn {
        color: rgba(0, 0, 0, 0.87);
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        cursor: pointer;
        border: none;
        outline: none;
  
        &.primary {
          color: #fff;
          background-color: #1976d2;
        }
  
        &.success {
          color: #fff;
          background-color: $color-green;
        }
  
        &:disabled {
          color: rgba(0, 0, 0, 0.26);
          background-color: rgba(0, 0, 0, 0.12);
          cursor: not-allowed;
        }
      }
    }
  }