@import "../../../variables.scss";

.Title {
  .Logo {
    width: auto;
    height: 6.5rem;
    padding: 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.DashBoardMainTitle {
  background-color: rgba($color-light-grey, 0);
  height: 5.5rem;
  display: flex;
  align-items: center;

  background-image: linear-gradient(
    to right,
    rgba($color-gold, 1),
    rgba($color-gold, 0),
    rgba($color-gold, 0),
    rgba($color-light-grey, 0),
    rgba($color-light-grey, 0)
  );
  background-size: 200% 200%;
  animation: gradient 1s ;
}
