@import "../../../variables.scss";

.BackGround {
  @keyframes animate {
    0% {
      transform: scale(0) translateY(-90px) rotate(360deg);
      opacity: 1;
    }
    100% {
      transform: scale(1.3) translateY(-90px) rotate(-180deg);
      border-radius: 50%;
      opacity: 0;
    }
  }

  .wrapperFullLayout {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .boxAnimations {
      div {
        position: absolute;
        width: 4.6875rem;
        height: 4.6875rem;
        background-color: transparent;
        border: 0.5rem solid rgba($color-gold, 1);
        &:nth-child(1) {
          top: 12%;
          right: 42%;
          animation: animate 10s linear infinite;
        }
        &:nth-child(2) {
          top: 70%;
          right: 40%;
          animation: animate 7s linear infinite;
        }
        &:nth-child(3) {
          top: 17%;
          right: 6%;
          animation: animate 9s linear infinite;
        }
        &:nth-child(4) {
          top: 20%;
          right: 60%;
          animation: animate 10s linear infinite;
        }
        &:nth-child(5) {
          top: 69%;
          right: 8%;
          animation: animate 6s linear infinite;
        }
        &:nth-child(6) {
          top: 80%;
          right: 70%;
          animation: animate 12s linear infinite;
        }
        &:nth-child(7) {
          top: 60%;
          right: 80%;
          animation: animate 15s linear infinite;
        }
        &:nth-child(8) {
          top: 15%;
          left: 15%;
          animation: animate 16s linear infinite;
        }
        &:nth-child(9) {
          top: 90%;
          right: 25%;
          animation: animate 9s linear infinite;
        }
        &:nth-child(10) {
          top: 90%;
          left: 3%;
          animation: animate 5s linear infinite;
        }
      }
    }
  }
}

.FrameLayout {
    background-color: $color-gold;
    position: fixed;
    height: 200%;
    width: 100%;
    transform: rotate(-15deg);
    left: -80%;
    top: -50%;
  }
