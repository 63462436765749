@import "../../variables.scss";

@keyframes fadeInOutText {
  0% {
    // font-size: $text-mid-size !important;
    opacity: 0;
  }
  100% {
    // font-size: $text-start-size !important;
    opacity: 1;
  }
}

.Main {
  // font-family: "Poppins", sans-serif !important; // Use 'sans-serif' as a fallback
  // line-height: 1.5 !important;
  // font-weight: 500 !important;

  position: relative;
  overflow: hidden;
  background-color: black;

  // .customScroll {
  //   max-height: 95vh; /* or any value that suits your needs */
  //   overflow-y: auto;
  //   overflow-x: hidden;
  //   animation: fadeInOutText 1s linear;
  // }

  // /* Customizing the scrollbar: Works on Chrome, Edge, and Safari */
  // .customScroll::-webkit-scrollbar {
  //   width: 0.3rem;
  //   margin-left: 0.6rem; /* avoid width reflow */
  // }

  // .customScroll::-webkit-scrollbar-track {
  //   background-color: white;
  //   border-radius: 5px; /* You can add this to have rounded corners */
  // }

  // .customScroll::-webkit-scrollbar-thumb {
  //   background-color: orange;
  //   border-radius: 5px; /* You can add this to have rounded corners */
  // }

  // /* Firefox */
  // .customScroll {
  //   scrollbar-color: orange white;
  // }

  .Bg {
    background-color: black;
    .mainBG {
      position: absolute;
      top: -7.625rem;
      left: 0;
      right: -1.5rem;
      bottom: -5.2rem;
      // transform: scaleX(-1);
      background-image: url("../../assets/main-bg.webp"); /* Replace 'path_to_your_image.jpg' with the actual path to your image */
      background-size: cover; /* Ensures the image is fully visible within the element, though it might not cover the entire element */
      background-repeat: no-repeat; /* Prevents the image from repeating */
      background-position: center; /* Centers the image within the element */
      z-index: 11;
      animation: fadeInOutText 500ms linear;
    }
    .mainBGLayer {
      position: absolute;
      top: 0; /* Ensures the background covers the entire element vertically */
      left: 0; /* Ensures the background covers the entire element horizontally */
      right: 0; /* Works with left: 0 to ensure horizontal coverage */
      bottom: 0; /* Works with top: 0 to ensure vertical coverage */
      z-index: 12;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), black);
    }

    $text-start-size: 12px;
    $text-mid-size: 18px;
    $text-end-size: 20px;

    .mainBGContent {
      z-index: 13;
      max-width: 43rem;

      hr {
        position: relative;
        width: 114%;
        margin-left: -7%;
      }

      p {
        font-size: 1.125rem;
        line-height: 1.9rem;
        font-weight: 600;
      }

      display: inline-block;
      animation: fadeInOutText 1s linear;
    }

    h1 {
      font-size: 3rem;
      // line-height: 3.875rem;
      font-weight: 700;
      margin-top: 0rem;
      // margin-bottom: 1.125rem;
      position: relative;
      padding-left: 3.375rem;
      color: $color-gold !important;

      span {
        display: block;
      }
    }

    h1:before {
      background-color: $color-gold;
      content: "";
      position: absolute;
      left: 0;
      top: 2.4rem;
      height: 0.25rem;
      width: 2rem;
      border-radius: 0.625rem;
    }
  }

  .fadeEnter {
    opacity: 0;
    transform: translateY(-100%);
  }

  .fadeEnterActive {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 500ms, transform 500ms;
  }

  .fadeExit {
    opacity: 1;
    transform: translateY(0%);
  }

  .fadeExitActive {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 500ms, transform 500ms;
  }
}
