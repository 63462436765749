.Bag {
  .PaymentMethods {
    margin-top: 20px;
    .PaymentMethod {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .FormCheck {
        margin-right: 10px;
      }
    }
  }
}