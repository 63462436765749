.toast-container {
  position: fixed;
  padding: 1rem;
  right: 0;
  z-index: 1050; // High z-index to ensure it's above most elements
}

.toast-wrapper {
  margin-bottom: 1rem; // Space between toasts
}
