@import "../../../variables.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
  height: 100vh;
  overflow: hidden;
  color: #ccc;
  background-color: $color-dark;
  // border-radius: 0.25rem;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5.5rem;

    img {
      width: 3.8rem;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba($color-gold, 0.1);
    margin-bottom: 0.5rem;

    .menuItem {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      margin-top: 0.5rem;
      text-decoration: none;
      border-radius: 0.25rem;
      color: #ccc;
      transition: background-color 0.3s, color 0.3s;
      padding: 0.5rem;

      &:hover {
        background-color: #555;
        color: $color-gold;
      }
      &.isSelected {
        background-color: #555;
        color: $color-gold;
      }
    }

    .bg-gray-700 {
      background-color: #4a4a4a;
    }

    // .tooltipDot {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 0.4rem;
    //   height: 0.4rem;
    //   margin-top: 0.5rem;
    //   margin-left: 0.5rem;
    //   background-color: #6610f2;
    //   border-radius: 50%;
    // }
  }

  .logout {
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    margin-top: auto;
    background-color: $color-darker;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    
    &:hover {
      background-color: rgba($color-darker, 0.5);
      color: red;
    }
  }
}
